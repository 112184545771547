<template>
  <modal @update:show="onShowChange" :show="show">
    <h6 slot="header" class="modal-title">{{ item.id ? this.$t('message.edit') : this.$t('message.new') }}</h6>

    <base-input required label="Text" v-model="item.name" :validator="$v.item.name"></base-input>

    <template slot="footer">
      <base-button class="ml-auto" type="link" @click="onCancelClick">{{$t('message.cancel')}}</base-button>
      <base-button type="primary" @click="onSaveClick" :disabled="$v.$invalid">{{$t('message.save')}}</base-button>
    </template>
  </modal>
</template>

<script>

import { required, maxLength } from "vuelidate/lib/validators";

export default {
  name: "options-form",
  props: {
    item: {
      type: Object
    },
    label: {
      type: String
    },
    show: {
      type: Boolean
    }
  },
  validations: {
    item: {
      name: {required, maxLength: maxLength(100)},
    },
  },
  methods: {
    onCancelClick() {
      this.$v.$reset()
      this.onShowChange(false);
    },
    onSaveClick: async function() {
      const { $store, item } = this;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return
      }

      if (item.id) {
        await $store.dispatch("updateOption", {
          id: item.id,
          data: item
        });
      } else {
        await $store.dispatch("createOption", { data: item });
      }

      this.$v.$reset()
      this.onShowChange(false);
      this.$emit("changed");
    },
    onShowChange(value) {
      this.$emit("update:show", value);
    }
  }
};
</script>
